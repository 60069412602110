<template lang="pug">
div(class="note-edit")
    div(class="note-edit__body")
        z-input(label="Наименование" ref="name_input" v-model="noteData.name" autofocus)
        z-checkbox(label="Приватная заметка" v-model="noteData.private")
        div(class="note-edit__body__buttons")
            z-btn(class="note-edit__body__buttons_button" :disable="!valid" :loading="isSaving" @click="saveNote") Сохранить
            z-btn(class="note-edit__body__buttons_button" @click="cancelEdit") Отмена
</template>

<script setup>
import {defineProps, defineEmits, ref, computed} from 'vue'
import axios from 'axios'
import {notifyErrors, notifySuccess} from '../../plugins/notify'
import ZCheckbox from '../../components/z-checkbox'

const props = defineProps({
    note:{},
    uuid:{},
    is_new:{type:Boolean}
})

const emit = defineEmits(['cancelEdit', 'saved'])

const noteData = ref({
    name: '',
    id: '',
    private: false
})

const tagGame = ref(false)
const isSaving = ref(false)
const valid = computed(()=> !!noteData.value.name)

function cancelEdit () {
    emit('cancelEdit')
}

function saveNote () {
    isSaving.value = true

    let postData = { ...noteData.value }
    postData['uuid'] = props.uuid

    axios.post('/save_note/', postData)
        .then(response => {
            isSaving.value = false
            emit('saved', response.data)
            notifySuccess(['Заметка сохранена'], 'main')
        })
        .catch(error => {
            isSaving.value = false
            notifyErrors(error, 'main', 'Ошибка сохранения заметки')
        })
}

function created () {
    if (!props.is_new && props.note) {
        noteData.value = { ...props.note }
    }
}
created()

</script>

<style scoped>
    .note-edit{
        padding: var(--indent-size);
        position: absolute;
        width: 100%;
        z-index: 6;
    }

    .note-edit__body{
        background: var(--editor-background);
        padding: var(--indent-size);
        box-shadow: var(--droopdown-box-shadow);
        overflow: hidden;
    }

    .note-edit__body__buttons{
        margin-top: 1rem;
        height: var(--block-height-medium);
        line-height: var(--block-height-medium);
        display: flex;
    }

    .note-edit__body__buttons_button{
        margin-right: var(--indent-size);
    }

</style>
