<template lang="pug">
div(class="notes")
    div(class="notes__header")
        div(class="notes__header__select")
            z-select(placeholder="Заметка не выбрана"
                      :items="notesList"
                      v-model="currentNote"
                      :titleFunction="titleFunction"
                      :bordered="false"
                      :show-icon="false"
                      @startSelect="getNotesList"
                      @change="noteChange")
                template(v-slot:item="listItem")
                    notes-list-item(:item="listItem.item" @favoriteClick="favoriteClick")
                template(v-slot:append-inner)
                    div(v-show="isRedis" class="notes__header__select__save")
                        mdicon(name="content-save-outline")
        z-btn(icon="plus" :transparent="true" @click="addNoteStart" class="notes__header__select__btn")
        z-dropdown-menu(direction='right')
            template(v-slot:trigger)
                z-btn(icon="dots-vertical" :transparent="true" :disable="!isCurrentNote" :loading="isLoading" :loading_timeout="500" class="notes__header__select__btn")
            template(v-slot:body)
                ul(class="notes__header__menu_ul " dropdown-closer)
                    li(class="z-list-item notes__header__menu_li panel__header__menu_li-right" dropdown-closer @click="editNoteStart")
                        div(class="notes__header__menu_li-text") Редактировать
                    li(class="z-list-item notes__header__menu_li panel__header__menu_li-right" dropdown-closer @click="deleteNoteStart")
                        div(class="notes__header__menu_li-text") Удалить
        notes-note-edit(v-if="isEdit" :uuid="noteUuid" :note="currentNote" :is_new="isEditNew" @cancelEdit="cancelEdit" @saved="saved")
        z-confirm(v-if="isDelete" message="Удалить заметку?" @yes="deleteNoteYes" @no="deleteNoteNo")
    div(class="notes__body")
        div(class="notes__body__edit")
            div(class="notes__body__edit__container" @click="scrollFix" ref="notes__body__edit__container")
                z-editor(class="notes__body__edit__container__editor"
                    :key="noteKey"
                    v-model="noteText"
                    ref="notes_editor"
                    placeholder="Заметки для бога заметок"
                    @blur="saveNote"
                    @ready="editorReady"
                    @change="onTextChange"
                    rich
                    bulletList
                    menu
                    :preventEnter="false"
                )
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue'
import {v4 as uuidv4} from 'uuid'
import {dmp, patchApply} from '../../plugins/diff-match-patch';
import io from '../../plugins/socketio'
import axios from 'axios'
import {notifyErrors, notifySuccess} from '../../plugins/notify'
import ZSelect from "../../components/z-select"
import ZEditor from "../../components/z-editor"
import ZDropdownMenu from "../../components/z-dropdown-menu"
import NotesNoteEdit from "./Notes-note-edit"
import ZConfirm from "../../components/z-confirm"
import NotesListItem from "./Notes-list-item"

const props = defineProps({panel:{}})
const emit = defineEmits(['append_to_route'])

const noteUuid = uuidv4()
const notesList = ref([])
const currentNote = ref({})
const noteKey = ref(0)
const isEdit = ref(false)
const isEditNew = ref(false)
const isDelete = ref(false)
const noteText = ref('')
let prevText = ''
const isRedis = ref(false)
const isLoading = ref(false)
const refEditor = ref(null)
const editorMethods = {}

const isCurrentNote = computed(()=>!!currentNote.value && !!currentNote.value.id)

io.on('connect', getNote)
io.on('reconnect', getNote)
io.on('error', getNote)

io.on('note_diff', data=>{
    updateNoteText(data, true, data => patchApply(data.diffs, noteText.value))
})

io.on('note_saved', data => {
    data['id'] = data.note.id
    updateNoteText(data, false, data => data.note.text)
})

const titleFunction  = (value) => (value) ? value.name : value

function editorReady({ editor, preventBlur } ){
    refEditor.value = editor
    editorMethods.preventBlur = preventBlur
}

async function getNotesList () {
    let response = await axios.get('get_notes')
    notesList.value = response.data
}

function getNote (noteId = null) {
    // console.log(noteId)
    if (!noteId && currentNote.value) {
        noteId = currentNote.value.id
    }
    if (noteId) {
        const getParams = {
            id: noteId,
            uuid: noteUuid
        }

        isLoading.value = true
        io.emit('get_note', getParams, response => {
            noteText.value = response.text
            prevText = response.text
            currentNote.value = response.note
            noteKey.value = noteId
            isRedis.value = response.is_redis
            localStorage.setItem('last_note_id', noteId)
            emit('append_to_route', { note_id: noteId })
            isLoading.value = false
            // console.log(noteId)
        })
    }
}

function addNoteStart () {
    isEdit.value = true
    isEditNew.value = true
}

function editNoteStart () {
    isEdit.value = true
    isEditNew.value = false
}

function deleteNoteStart () {
    isDelete.value = true
}

function deleteNoteYes () {
    let noteData = { 'id': currentNote.value.id }
    axios.post('/delete_note/', noteData).then(response => {
        if (response.data.is_valid) {
            isDelete.value = false
            currentNote.value = null
            noteKey.value = 0
            notifySuccess(['Заметка удалена'], 'main')
        }
    }).catch(error => {
        notifyErrors(error, 'main', 'Ошибка удаления заметки')
    })
}

function deleteNoteNo () {
    isDelete.value = false
}

function cancelEdit () {
    isEdit.value = false
}

function saved (note) {
    isEdit.value = false
    getNote(note.id)
}

function noteChange () {
    getNote(currentNote.value.id)
    isDelete.value = false
    isEdit.value = false
}

function saveNote () {
    if (currentNote.value) {
        const noteData = {
            id: currentNote.value.id,
            text: noteText.value,
            uuid: noteUuid
        }
        axios.post('/save_note/', noteData).then(response => {
            isRedis.value = false
        })
    }
}

function onTextChange (html) {
    if (refEditor.value.isFocused) {
        let diffs = dmp.patch_make(prevText, html)
        prevText = html
        isRedis.value = true

        io.emit('note_diff', {
            id: currentNote.value.id,
            uuid: noteUuid,
            diffs: dmp.patch_toText(diffs)
        })
    }
}

function updateNoteText (data, isNoteRedis, getTextFunction) {
    if (!currentNote.value || data.id !== currentNote.value.id || data.uuid === noteUuid) { // Не обрабатываем если это другая заметка, если это текущий рекдактор
        return null
    }
    let newText = getTextFunction(data)
    noteText.value = newText
    prevText = newText
    isRedis.value = isNoteRedis
}

function favoriteClick (note) {
    note.is_favorite = !note.is_favorite
    axios.post('/set_favorite_note/', note)
        .then(response => {
            notesList.value = response.data.notes
        })
        .catch(error => {
            notifyErrors(error, 'main', 'Ошибка установки \nизбранной заметки')
        })
}

function created () {
    getNotesList()
    let noteId = null
    if ('note_id' in props.panel) {
        noteId = props.panel.note_id
    } else {
        noteId = localStorage.getItem('last_note_id')
    }
    getNote(noteId)
}
created()

</script>

<style scoped>
    .notes {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    .notes__header {
        height: var(--block-height-big);
        min-height: var(--block-height-big);
        flex: 0;
        display: flex;
        position: relative;
    }

    .notes__header__select{
        flex: 1;
        display: flex;
    }

    .notes__header__select ::v-deep(.z-select){
        margin-bottom: 0;
    }

    .notes__header__select__save{
        height: 100%;
        width: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .notes__header__select ::v-deep(.z-select input),
    .notes__header__select ::v-deep(.z-select)
    {
        cursor: pointer;
    }

    .notes__header__select ::v-deep(.z-select input){
        padding-left: 0;
    }

    .notes__header__select ::v-deep(.z-select-focused input),
    .notes__header__select ::v-deep(.z-select-focused)
    {
        cursor: text;
    }

    .notes__header__select ::v-deep(.z-select__input__container){
        padding-left: var(--indent-size);
    }

    .notes__header__select ::v-deep(.z-select__input__container:hover){
        background: var(--transparent-hover-background);
        filter: var(--transparent-hover-filter);
    }

    .notes__header__select ::v-deep(.z-select-focused .z-select__input__container:hover){
        background: none;
        filter: none;
    }

    .notes__header__select__btn{
        max-width: 2.5rem;
        flex: 0;
    }

    .notes__header ::v-deep(.z-select){
        margin-bottom: 0;
    }

    .notes__header ::v-deep(.z-select__dropdown){
        width: calc(100% + 5rem - 1px);
        /*margin-right: 2px;*/
        /*margin-left: 2px;*/
    }

    .notes__header ::v-deep(.z-select__dropdown__list) {
        /*height: 15rem;*/
        max-height: calc(var(--vh100) - 8rem);
    }


    .notes__header ::v-deep(.v-dropdown-menu__container){
        transform: translateX(-1px);
        background: var(--panel__header__menu_background);
    }

    .notes__header ul {
        margin: 0;
        padding: 0;
        color: var(--panel__header__menu_color);
        position: relative;
        /*transform: translateX(-1px);*/
    }
    .notes__header ::v-deep(.z-select__dropdown__list__item) {
        padding-right: 0.3rem;
    }
    .notes__body {
        flex: 1;
        padding: var(--indent-size);
        background: var(--editor-background);
        display: flex;
        word-break: break-word;
        /*overflow: visible;*/
    }

    .notes__body__edit{
        flex: 1;
        position: relative;
        cursor: default;
    }

    /*.notes__body__edit_redis{*/
    /*    !*background: #42b983;*!*/
    /*    outline: var(--forum-post-redis_border);*/
    /*}*/

    .notes__header__select-footer {
        height: var(--block-height-medium);
        max-height: var(--block-height-medium);
        display: flex;
        justify-content: flex-end;
        border-top: var(--border-color-alter) 1px solid;
    }

    .notes__header__select-footer ::v-deep(.z-btn) {
        max-width: 3rem;
    }

    .notes__body__edit__container__editor{
        overflow: visible;
    }

    .notes__body__edit__container,
    .notes__body__edit__container__editor,
    .notes__body__edit__container ::v-deep(.ProseMirror)
    {
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .notes__body__edit__container{
        /*overflow-y: auto;*/
        cursor: default;
        /*overflow-anchor: none;*/
    }

    .notes__body__edit__container ::v-deep(.ProseMirror){
        flex: 1;
        /*overflow-y: auto;*/
        text-align: justify;
        padding-right: 0.5rem;
        cursor: text;
        overflow-y: auto;
    }

    .notes__body__edit-toolbar__container{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        transform: translateY(-0.5rem);
        z-index: -1;
    }

    .notes__body__edit-toolbar__container__inner{
        position: absolute;
        display: flex;
        background: var(--editor-toolbar-background);
        transform: translateY(-2.5rem);
    }

    .notes__body__edit-toolbar__container__inner  ::v-deep(.z-btn){
        max-width: 3rem;
    }

    .is-active{
        z-index: 1;
    }

    .notes__body__edit p{
        margin-bottom: var(--indent-size);
        white-space: normal;
        word-break: break-word;
    }

</style>
