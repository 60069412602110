<template lang="pug" >
div(:class="{'item':true, 'item-favorite':item.is_favorite}")
    div(class="item__name") {{item.name}}
    div(class="item__creator") {{item.creator.name}}
    div(class="item__favorite" @click.prevent.stop="favoriteClick")
        mdicon(:name="iconName")
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'

const props = defineProps({ item: {} })
const emit = defineEmits(['favoriteClick'])
const iconName = computed(() => (props.item.is_favorite) ? 'star' : 'star-outline')

function favoriteClick () {
    emit('favoriteClick', props.item)
}
</script>

<style scoped>
    .item{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .item__name{
        flex: 1;
    }

    .item__creator{
        flex: 0;
        max-width: 5rem;
        font-size: var(--font-size-small);
        margin-left: var(--indent-size);
    }

    .item__favorite{
        flex: 0;
        width: 1.5rem;
        color: var(--panel__header__menu_background);
        margin-left: 0.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .item__favorite:hover{
        color: var(--favorite-color);
    }

    .item-favorite>.item__favorite{
        color: var(--favorite-color);
    }
</style>
